<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.11994 19.3959C3.71127 19.3959 3.35869 19.2476 3.06219 18.9511C2.76552 18.6545 2.61719 18.3017 2.61719 17.8929V6.11238C2.61719 5.70354 2.76552 5.35079 3.06219 5.05412C3.35869 4.75762 3.71127 4.60938 4.11994 4.60938H19.8774C20.2861 4.60938 20.6387 4.75762 20.9352 5.05412C21.2319 5.35079 21.3802 5.70354 21.3802 6.11238V17.8929C21.3802 18.3017 21.2319 18.6545 20.9352 18.9511C20.6387 19.2476 20.2861 19.3959 19.8774 19.3959H4.11994ZM4.12219 18.1989H19.8752C19.9522 18.1989 20.0228 18.1668 20.0869 18.1026C20.1509 18.0385 20.1829 17.968 20.1829 17.8911V6.11413C20.1829 6.03729 20.1509 5.96679 20.0869 5.90262C20.0228 5.83846 19.9522 5.80638 19.8752 5.80638H4.12219C4.04519 5.80638 3.9746 5.83846 3.91044 5.90262C3.84644 5.96679 3.81444 6.03729 3.81444 6.11413V17.8911C3.81444 17.968 3.84644 18.0385 3.91044 18.1026C3.9746 18.1668 4.04519 18.1989 4.12219 18.1989Z"
      fill="currentColor"
    />
    <path
      d="M6.92906 10.4113C6.74464 10.4113 6.58095 10.4475 6.43797 10.5201C6.29707 10.5905 6.17792 10.6931 6.08054 10.8278C5.98522 10.9625 5.9127 11.1251 5.86297 11.3157C5.81324 11.5064 5.78837 11.7208 5.78837 11.9591C5.78837 12.2803 5.82774 12.5549 5.90648 12.7828C5.98729 13.0086 6.11162 13.1817 6.27946 13.3018C6.4473 13.42 6.66383 13.479 6.92906 13.479C7.11347 13.479 7.29789 13.4583 7.48231 13.4168C7.50909 13.4109 7.53619 13.4046 7.56362 13.3979C7.82374 13.3346 8.08839 13.5203 8.08839 13.788C8.08839 13.9452 7.99426 14.0893 7.84452 14.1372C7.72684 14.1748 7.61024 14.2041 7.49474 14.225C7.29996 14.2623 7.08136 14.2809 6.83892 14.2809C6.37063 14.2809 5.98522 14.1846 5.68269 13.9918C5.38224 13.7971 5.15949 13.5256 5.01444 13.1775C4.8694 12.8273 4.79688 12.4191 4.79688 11.9529C4.79688 11.6089 4.8435 11.294 4.93674 11.008C5.02999 10.7221 5.16674 10.4745 5.34702 10.2652C5.52729 10.0559 5.75004 9.89429 6.01526 9.78032C6.28049 9.66636 6.58509 9.60938 6.92906 9.60938C7.15492 9.60938 7.38077 9.63838 7.60663 9.6964C7.70503 9.72055 7.80149 9.74876 7.89603 9.78102C8.10536 9.85246 8.19607 10.089 8.11449 10.2945C8.02466 10.5209 7.76259 10.6223 7.53444 10.5371C7.50156 10.5248 7.46864 10.5129 7.43568 10.5014C7.2637 10.4413 7.09482 10.4113 6.92906 10.4113Z"
      fill="currentColor"
    />
    <path
      d="M13.0894 13.5631C13.0894 13.9252 12.7958 14.2187 12.4337 14.2187C12.0818 14.2187 11.7566 14.0306 11.5812 13.7255L9.888 10.7811H9.86003C9.86832 10.9241 9.87557 11.0681 9.88179 11.2132C9.888 11.3582 9.89422 11.5033 9.90044 11.6483C9.90665 11.7913 9.91287 11.9353 9.91909 12.0803V13.7883C9.91909 14.026 9.72635 14.2187 9.48861 14.2187C9.25086 14.2187 9.05813 14.026 9.05813 13.7883V10.3266C9.05813 9.96653 9.35001 9.67465 9.71007 9.67465C10.0588 9.67465 10.3811 9.86033 10.556 10.162L12.2471 13.0781H12.2688C12.2647 12.9372 12.2595 12.7973 12.2533 12.6585C12.2471 12.5196 12.2409 12.3808 12.2346 12.242C12.2305 12.1031 12.2264 11.9643 12.2222 11.8255V10.1082C12.2222 9.86877 12.4163 9.67465 12.6558 9.67465C12.8953 9.67465 13.0894 9.86877 13.0894 10.1082V13.5631Z"
      fill="currentColor"
    />
    <path
      d="M15.6878 9.67465C16.2742 9.67465 16.7021 9.80104 16.9714 10.0538C17.2408 10.3046 17.3755 10.6506 17.3755 11.092C17.3755 11.2909 17.3455 11.4815 17.2854 11.6639C17.2253 11.8441 17.1269 12.0047 16.9901 12.1456C16.8554 12.2865 16.6751 12.3984 16.4493 12.4813C16.2234 12.5621 15.9447 12.6025 15.6132 12.6025H15.1998V13.737C15.1998 14.0031 14.9841 14.2187 14.718 14.2187C14.452 14.2187 14.2363 14.0031 14.2363 13.737V10.4324C14.2363 10.0139 14.5756 9.67465 14.9941 9.67465H15.6878ZM15.6381 10.4641H15.1998V11.813H15.5168C15.6971 11.813 15.8536 11.7892 15.9862 11.7416C16.1188 11.6939 16.2214 11.6193 16.2939 11.5178C16.3664 11.4162 16.4027 11.2857 16.4027 11.1261C16.4027 10.9024 16.3405 10.7366 16.2162 10.6288C16.0918 10.519 15.8991 10.4641 15.6381 10.4641Z"
      fill="currentColor"
    />
    <path
      d="M17.7578 15.5552C17.6335 15.5552 17.5247 15.548 17.4315 15.5335C17.2736 15.5129 17.1414 15.413 17.0552 15.2792L17.0494 15.2702C16.9771 15.158 16.9771 15.0138 17.0494 14.9016L17.0708 14.8684C17.1419 14.7581 17.2739 14.7044 17.4035 14.7254C17.4781 14.7399 17.5578 14.7471 17.6428 14.7471C17.7547 14.7471 17.8552 14.7254 17.9443 14.6819C18.0334 14.6404 18.1038 14.5606 18.1556 14.4425C18.2075 14.3244 18.2334 14.1545 18.2334 13.9328V10.1564C18.2334 9.89034 18.449 9.67465 18.7151 9.67465C18.9812 9.67465 19.1969 9.89034 19.1969 10.1564V13.9266C19.1969 14.3161 19.1357 14.6301 19.0135 14.8683C18.8933 15.1087 18.7244 15.2828 18.5069 15.3905C18.2914 15.5003 18.0417 15.5552 17.7578 15.5552Z"
      fill="currentColor"
    />
  </svg>
</template>
