<script setup lang="ts">
type Network = 'instagram' | 'facebook' | 'twitter' | 'tiktok' | 'youtube' | 'kwai'

const props = defineProps<{ company: Network; link: string }>()
const theme = useStoreTheme()
const companyIconMap: Record<Network, string> = {
  instagram: 'ph:instagram-logo-fill',
  facebook: 'ic:round-facebook',
  twitter: 'mdi:twitter',
  tiktok: 'ic:baseline-tiktok',
  youtube: 'mdi:youtube',
  kwai: 'ic:outline-video-camera-front',
}
</script>

<template>
  <a
    target="_blank"
    :href="props.link"
    :style="{ color: theme.primary }"
    class="rounded-xl p-1 bg-white w-[44px] flex align-middle justify-center"
    v-bind="$attrs"
  >
    <RIcon size="24" :name="companyIconMap[props.company]" />
  </a>
</template>
