<script lang="ts" setup>
const config = useRuntimeConfig()
const globalStore = useGlobalStore()

const queryString = computed(() => {
  return new URLSearchParams({
    key: config.public.GOOGLE_MAPS_KEY,
    q: globalStore.mapsAddress,
    zoom: '13',
  })
})
</script>

<template>
  <ClientOnly>
    <iframe
      loading="lazy"
      width="100%"
      height="100%"
      class="store-map rounded-lg border-1 border-gray-200 w-full"
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://www.google.com/maps/embed/v1/place?${queryString}`"
      v-bind="$attrs"
    >
    </iframe>
  </ClientOnly>
</template>

<style scoped>
.place-card .default-card {
  display: none;
}
</style>
