import { OrderPaymentType } from '@rediredi/types'

export const usePaymentsOffered = () => {
  const currentStore = useCurrentStore()

  const acceptedPayments = computed(() => {
    const storePayments = currentStore.paymentMethodsOffered
    const enabledPayments = []

    if (!storePayments) return []

    if (storePayments.bankTransfer) {
      enabledPayments.push({ name: OrderPaymentType.BankTransfer, icon: 'mdi:bank-outline' })
    }

    if (storePayments.cash) {
      enabledPayments.push({ name: OrderPaymentType.Cash, icon: 'mdi:cash' })
    }

    if (storePayments.creditCard && storePayments.debitCard) {
      enabledPayments.push({ name: 'CARD', icon: 'mdi:credit-card-outline' })
    } else if (storePayments.creditCard) {
      enabledPayments.push({ name: OrderPaymentType.CreditCard, icon: 'mdi:credit-card-outline' })
    } else if (storePayments.debitCard) {
      enabledPayments.push({ name: OrderPaymentType.DebitCard, icon: 'mdi:credit-card-outline' })
    }

    if (storePayments.paymentLink) {
      enabledPayments.push({ name: OrderPaymentType.PaymentLink, icon: 'mdi:link' })
    }

    if (storePayments.pix) {
      enabledPayments.push({ name: OrderPaymentType.Pix, icon: 'ic:baseline-pix' })
    }

    if (storePayments?.boleto) {
      enabledPayments.push({ name: OrderPaymentType.Boleto, icon: 'mdi:account-balance' })
    }

    return enabledPayments
  })

  return {
    acceptedPayments,
  }
}
